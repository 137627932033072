import * as Actions from "@/store/actions.type";
import * as Mutations from "@/store/mutations.type";
import { getAuth } from "firebase/auth";
import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import * as Firebase from "@/common/firebase.js";
import * as Helpers from "@/store/helpers";
import {
  createServiceRequest,
  createServiceRequestPickUp,
  getAllServiceRequestPickUp,
  getAllServiceRequestDropOff,
  serviceRequestPickUpQuote,
  createReturnConsignment,
  getTrackingDetails,
  serviceRequestIncomingConsignmentDelivered,
  getAllServiceRequestDropOffCSV,
  getAllServiceRequestPickUpCSV
} from "@/common/salesAppApi";
import { getAllDealersUnprotected } from "@/common/salesAppApi";
import LOG_ACTIONS from "@/store/constants/log_actions.constants";
import MODULE from "@/store/constants/module.constants";
import getFirestoreCollectionConfig from "@/utils/FirestoreCollection";
const firestoreCollection = getFirestoreCollectionConfig();

export default {
  async [Actions.GET_ALL_DEALERS_UNPROTECTED]({ commit }) {
    commit(Mutations.SET_SERVICE_LOADING, true);
    try {
      const { data } = await getAllDealersUnprotected();
      commit(Mutations.SET_ALL_DEALERS, data.dealers);
    } catch (error) {
      commit(Mutations.SET_DEALER_ERROR, error);
    }
    commit(Mutations.SET_SERVICE_LOADING, false);
  },

  [Actions.UPDATE_SELECTED_DEALER]({ commit }, dealer) {
    commit(Mutations.SET_SELECTED_DEALER, dealer);
  },

  async [Actions.CREATE_SERVICE_REQUEST]({ commit }, payload) {
    let data;
    try {
      data = await createServiceRequest(payload);
    } catch (error) {
      commit(Mutations.SET_SERVICEREQUEST_ERROR, error);
    }
    return data;
  },

  async [Actions.CREATE_SERVICE_REQUEST_PICKUP]({ commit, dispatch }, payload) {
    let data = null;
    try {
      data = await createServiceRequestPickUp(payload);
      const currentUser = getAuth()?.currentUser;
      await dispatch(Actions.LOG_EVENT, {
        user: currentUser.email,
        action: LOG_ACTIONS.CREATE_SERVICE_REQUEST_PICKUP,
        module: MODULE.SERVICE_REQUEST,
        target: JSON.stringify({
          docId: data.data.updatedRequest.id,
          dealer: data.data.updatedRequest.basicInfo.name
        }),
        datePerformed: serverTimestamp()
      });
    } catch (error) {
      commit(Mutations.SET_SERVICEREQUEST_ERROR, error);
    }
    return data ? data : { data: { success: false } };
  },

  async [Actions.GET_ALL_SERVICEREQUESTS_PICKUP](
    { commit },
    { page = 1, filter, priorityId, type }
  ) {
    let errors = null;
    try {
      commit(Mutations.SET_SERVICE_LOADING, true);
      commit(Mutations.SET_ALL_SERVICEREQUESTS_PICKUP, []);
      commit(Mutations.SET_ALL_SERVICEREQUESTS_COUNT, 0);
      const {
        data: { serviceRequests, count }
      } = await getAllServiceRequestPickUp({
        page,
        filter: JSON.stringify(filter),
        priorityId,
        type
      });
      commit(Mutations.SET_ALL_SERVICEREQUESTS_PICKUP, serviceRequests);
      commit(Mutations.SET_ALL_SERVICEREQUESTS_COUNT, count);
    } catch (error) {
      if (error.message && error.message !== "Operation cancelled by user") {
        commit(Mutations.SET_SERVICEREQUEST_ERROR, error);
      }
    }
    commit(Mutations.SET_SERVICE_LOADING, false);
    return errors ? { errors } : null;
  },

  async [Actions.GET_ALL_SERVICEREQUESTS_DROPOFF](
    { commit },
    { page = 1, filter }
  ) {
    try {
      commit(Mutations.SET_SERVICE_LOADING, true);
      const {
        data: { serviceRequests, count }
      } = await getAllServiceRequestDropOff({ page, filter });
      commit(Mutations.SET_ALL_SERVICEREQUESTS_DROPOFF, serviceRequests);
      commit(Mutations.SET_ALL_SERVICEREQUESTS_COUNT_DROPOFF, count);
    } catch (error) {
      commit(Mutations.SET_SERVICEREQUEST_ERROR, error);
    }
    commit(Mutations.SET_SERVICE_LOADING, false);
  },
  async [Actions.SERVICE_PICKUP_REBOOK_RESEND]({ commit }, payload) {
    let data = null;
    try {
      data = await createServiceRequestPickUp({ ...payload, isRebook: true });
    } catch (error) {
      commit(Mutations.SET_SERVICEREQUEST_ERROR, error);
    }
    return data ? data : { data: { success: false } };
  },
  async [Actions.UPDATE_SERVICE_REQUEST_PICKUP]({ commit, dispatch }, payload) {
    let updated = false;
    try {
      const docRef = doc(
        Firebase.db,
        firestoreCollection.serviceRequestPickUp,
        payload.id
      );
      const docSnap = await getDoc(docRef);
      let currentData = null;
      if (docSnap.exists()) {
        currentData = docSnap.data();
        const { success, additionalChanges, emailType } =
          Helpers.serviceRequestUpdateHelper({
            changes: payload.changes,
            currentData
          });
        payload.emailType = emailType;
        await setDoc(
          doc(
            Firebase.db,
            firestoreCollection.serviceRequestPickUp,
            payload.id
          ),
          { ...payload.changes, ...(success && additionalChanges) },
          { merge: true }
        );
      } else {
        throw new Error("No such document!");
      }
      const currentUser = getAuth()?.currentUser;
      switch (payload.emailType) {
        case "quote":
          await serviceRequestPickUpQuote(payload);
          // Log Event for quote// To ASK if it is still needed to have separate logs for quote and update status// REASON: Multiple Robots can update at the same time
          await dispatch(Actions.LOG_EVENT, {
            user: currentUser.email,
            action: LOG_ACTIONS.SERVICE_PICKUP_QUOTE_ROBOT,
            module: MODULE.SERVICE_REQUEST,
            target: JSON.stringify({
              robots: payload.changes.robots.map((robot) => {
                return `${robot.robotName} (${robot.caseId})`;
              }),
              consignment: currentData.shipping.consignment
            }),
            datePerformed: serverTimestamp()
          });
          break;
        case "delivered":
          await serviceRequestIncomingConsignmentDelivered({ id: payload.id });
          break;
      }
      // User Logs
      if (!payload.no_log) {
        const formattedLog = Helpers.servicePickupLogFormatter({
          ...payload.changes,
          consignment: currentData.shipping.consignment,
          isUpdateSC: payload.isUpdateSC
        });
        if (formattedLog) {
          await dispatch(Actions.LOG_EVENT, {
            user: currentUser.email,
            action: formattedLog.action,
            module: MODULE.SERVICE_REQUEST,
            target: JSON.stringify(formattedLog.target),
            datePerformed: serverTimestamp()
          });
        }
      }
      updated = true;
    } catch (error) {
      console.log(error);
      commit(Mutations.SET_SERVICEREQUEST_ERROR, error);
    }
    return updated;
  },
  async [Actions.SERVICE_REQUEST_CREATE_RETURN_CONSIGNMENT](
    { commit, dispatch },
    payload
  ) {
    let data;
    try {
      const { robots } = payload;
      data = await createReturnConsignment(payload);
      const existingConsignment = payload.existingConsignment;
      let robotPayload = robots.filter((robot) => {
        return robot.sendReturnConsignment && !robot.returnConsignment;
      });
      if (existingConsignment) {
        robotPayload = robots.filter((robot) => {
          return (
            robot.returnConsignment &&
            robot.returnConsignment.shipping.consignment ===
              existingConsignment.shipping.consignment
          );
        });
      }
      const currentUser = getAuth()?.currentUser;
      await dispatch(Actions.LOG_EVENT, {
        user: currentUser.email,
        action: LOG_ACTIONS.SERVICE_PICKUP_CREATE_RETURN,
        module: MODULE.SERVICE_REQUEST,
        target: JSON.stringify({
          robots: robotPayload,
          consignment: data.data.consignment,
          isRebook: payload.existingConsignment ? true : false
        }),
        datePerformed: serverTimestamp()
      });
    } catch (error) {
      data = { data: { success: false } };
      commit(Mutations.SET_SERVICEREQUEST_ERROR, error);
    }
    return data;
  },
  async [Actions.SERVICE_REQUEST_GET_TRACKING]({ commit }, payload) {
    try {
      const { data } = await getTrackingDetails(payload);
      if (data.data) {
        return data.data;
      } else {
        throw new Error("No tracking information available");
      }
    } catch (error) {
      commit(Mutations.SET_SERVICEREQUEST_ERROR, error);
      return {
        ...error,
        message:
          error.message === "No tracking information available"
            ? "No tracking information available"
            : "Something went wrong with Open Freight API"
      };
    }
  },
  async [Actions.GET_ALL_SERVICEREQUESTS_DROPOFF_CSV]({ commit }, { filter }) {
    try {
      const {
        data: { serviceRequests }
      } = await getAllServiceRequestDropOffCSV({ filter });
      return serviceRequests;
    } catch (error) {
      commit(Mutations.SET_SERVICEREQUEST_ERROR, error);
    }
  },
  async [Actions.GET_ALL_SERVICEREQUESTS_PICKUP_CSV](
    { commit },
    { filter, priorityId, type }
  ) {
    try {
      const {
        data: { serviceRequests }
      } = await getAllServiceRequestPickUpCSV({
        filter: JSON.stringify(filter),
        priorityId,
        type
      });
      return serviceRequests;
    } catch (error) {
      commit(Mutations.SET_SERVICEREQUEST_ERROR, error);
    }
  }
};
