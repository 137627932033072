import { uploadFileByAPI } from "@/common/salesAppApi";
import LOG_ACTIONS from "@/store/constants/log_actions.constants";
import moment from "moment";
import { getAuth } from "firebase/auth";
import { serverTimestamp } from "firebase/firestore";

export const handleFileChangeAPI = async (e) => {
  try {
    const { url } = await uploadFileByAPI(
      `${e.target.files[0].name.replace(/\s+/g, "-").toLowerCase()}`,
      e.target.files[0]
    );
    return url;
  } catch (error) {
    console.log(error);
  }
};

export const parseServiceDropOffToCsvFormatFull = (services) => {
  const headings = [
    "DATE",
    "BRANCH",
    "CUSTOMER TYPE",
    "NAME",
    "EMAIL",
    "PHONE",
    "ADDRESS",
    "STATE",
    "CITY",
    "ADDRESSLINE",
    "POSTCODE",
    "DELIVERY NAME",
    "DELIVERY EMAIL",
    "DELIVERY PHONE",
    "DELIVERY ADDRESS",
    "DELIVERY STATE",
    "DELIVERY CITY",
    "DELIVERY ADDRESSLINE",
    "DELIVERY POSTCODE",
    "MODEL",
    "SERIAL NUMBER",
    "PURCHASE DATE",
    "INCLUSION",
    "PROBLEM",
    "IMAGES"
  ];
  return [
    headings,
    ...services.map((service) => {
      const imgUrls =
        service.imgUrl && Array.isArray(service.imgUrl)
          ? service.imgUrl?.join(", ")
          : service.imgUrl;
      return [
        service.dateDropOff?.date ?? "",
        service.dropOffBranch?.label ?? "",
        service.consumer?.toUpperCase() ?? "",
        service.consumerInfo?.name ?? "",
        service.consumerInfo?.email ?? "",
        service.consumerInfo?.phone ?? "",
        service.consumerInfo?.address?.address ?? "",
        service.consumerInfo?.address?.state ?? "",
        service.consumerInfo?.address?.city ?? "",
        service.consumerInfo?.address?.addressLine ?? "",
        service.consumerInfo?.address?.postalCode ?? "",
        service.deliveryInfo?.name ?? "",
        service.deliveryInfo?.email ?? "",
        service.deliveryInfo?.phone ?? "",
        service.deliveryInfo?.address?.address ?? "",
        service.deliveryInfo?.address?.state ?? "",
        service.deliveryInfo?.address?.city ?? "",
        service.deliveryInfo?.address?.addressLine ?? "",
        service.deliveryInfo?.address?.postCode ?? "",
        service.model ?? "",
        service.serialNumber ?? "",
        service.purchaseDate ?? "",
        service.inclusion?.join(", ") ?? "",
        service.problem ?? "",
        imgUrls ?? ""
      ];
    })
  ];
};

export const parseServicePickUpToCsvFormatFull = (services) => {
  const headings = [
    "DATE",
    "BRANCH",
    "SERVICE CALL ID",
    "PRIORITYID",
    "NAME",
    "EMAIL",
    "PHONE",
    "ADDRESS",
    "STATE",
    "CITY",
    "ADDRESSLINE",
    "POSTCODE",
    "SERVICE AGREEMENT",
    "CONSIGNMENT ID",
    "LABEL PDF",
    "MANIFEST LABEL",
    "PICKUP ID",
    "STATUS",
    "PICKUP INSTRUCTION",
    "DESCRIPTION OF FAULTS",
    "ROBOTS"
  ];
  return [
    headings,
    ...services.map((service) => {
      const robots = service.robots?.length
        ? service.robots
            ?.map(
              (robot) =>
                `${robot.robotName}(SN:${robot.serialNumber}, ${robot.caseId}${
                  robot.returnConsignment
                    ? ` Return Consignment : ${robot.returnConsignment?.shipping?.consignment}`
                    : ""
                })`
            )
            .join(", ")
        : "";
      return [
        service?.date ?? "",
        service?.branch?.value ?? "",
        service?.caseId ?? "",
        service?.priorityId ?? "",
        service?.basicInfo?.name ?? "",
        service?.basicInfo?.email ?? "",
        service?.basicInfo?.phone ?? "",
        service?.basicInfo?.address?.address ?? "",
        service?.basicInfo?.address?.state ?? "",
        service?.basicInfo?.address?.city ?? "",
        service?.basicInfo?.address?.addressLine ?? "",
        service?.basicInfo?.address?.postalCode ?? "",
        service?.agreement ? "YES" : "NO",
        service?.shipping?.consignment ?? "",
        service?.shipping?.label_pdf ?? "",
        service?.manifest?.label ?? "",
        service?.pickup?.pickup ?? "",
        service?.status.toUpperCase() ?? "",
        service?.notes ?? "",
        service?.robotNotes ?? "",
        robots ?? ""
      ];
    })
  ];
};

export const servicePickupLogFormatter = (changes) => {
  if (changes.status && changes.status === "Cancelled") {
    return {
      action: LOG_ACTIONS.SERVICE_PICKUP_CANCEL,
      target: {
        consignment: changes.consignment
      }
    };
  }

  if (changes.status) {
    return {
      action: LOG_ACTIONS.SERVICE_PICKUP_UPDATE_STATUS,
      target: {
        status: changes.status,
        consignment: changes.consignment
      }
    };
  }

  if (changes.isUpdateSC && changes.robots) {
    return {
      action: LOG_ACTIONS.SERVICE_PICKUP_UPDATE_ROBOT_SC,
      target: {
        robots: changes.robots
          .map(
            (robot) =>
              `${robot.robotName}(${robot.caseId ?? "-"}/${robot.serialNumber})`
          )
          .join(", "),
        consignment: changes.consignment
      }
    };
  }

  if (changes.robots) {
    return {
      action: LOG_ACTIONS.SERVICE_PICKUP_UPDATE_ROBOT,
      target: {
        robots: changes.robots
          .map(
            (robot) =>
              `${robot.robotName}(${
                robot.caseId
              }) - ${robot.status.toUpperCase()}`
          )
          .join(", "),
        consignment: changes.consignment
      }
    };
  }

  if (changes.basicInfo) {
    return {
      action: LOG_ACTIONS.SERVICE_PICKUP_UPDATE_ADDRESS,
      target: {
        address: changes.basicInfo.address,
        consignment: changes.consignment
      }
    };
  }
};

function isTimestamp(obj) {
  return (
    obj &&
    typeof obj === "object" &&
    typeof obj._seconds === "number" &&
    typeof obj._nanoseconds === "number"
  );
}

export const formatTimeStamp = (timestamp, format = "YYYY-MM-DD h:mm:ss A") => {
  if (!isTimestamp(timestamp)) {
    return typeof timestamp === "string" ? timestamp : "-";
  }

  const milliseconds =
    timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000;
  const date = new Date(milliseconds);
  return moment(date).format(format);
};

/**
 * @typedef {Object} PickupUpdateHelperResponse
 * @property {boolean} success
 * @property {Object} additionalChanges
 * @property {string} emailType
 */
/**
 * @param {Object} changes The changes to apply
 * @param {Object} currentData The current data on database
 * @returns {PickupUpdateHelperResponse} PickupUpdateHelperResponse - status, additionalChanges and emailType
 */

export const serviceRequestUpdateHelper = ({ changes, currentData }) => {
  let success = false;
  let additionalChanges = {};
  let emailType = "";

  // Check for delivered email
  if (
    changes.status &&
    changes.status === "Received at Repair Facility" &&
    currentData.status === "booked"
  ) {
    emailType = "delivered";
    success = true;
  }
  // Check for quoting
  if (changes.robots && !changes.status) {
    const existingQuotedRobots = currentData.robots
      .filter((robot) => robot.status === "quoted")
      .map((robot) => {
        return robot.serialNumber;
      });

    const quotedRobots = changes.robots.filter((robot) => {
      return (
        robot.status === "quoted" &&
        !existingQuotedRobots.includes(robot.serialNumber)
      );
    });
    if (quotedRobots.length > 0) {
      console.log("Quoting");
      emailType = "quote";
      success = true;
    }
  }
  // Check for completed status
  if (
    changes.status &&
    (changes.status === "Closed" || changes.status === "Cancelled")
  ) {
    additionalChanges.dateCompleted = serverTimestamp();
    additionalChanges.completedBy = getAuth()?.currentUser.email;
    success = true;
  }

  return { success, additionalChanges, emailType };
};
