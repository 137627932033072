export default {
  currentUser(state) {
    return state.user;
  },
  isCurrentUserDealer(state) {
    return state.user && state.user.role === "DEALER";
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  getErrors(state) {
    return state.errors;
  },
  getLoading(state) {
    return state.loading;
  },
  getUsers(state) {
    return state.users;
  }
};
